
<template>
  <div class="table">
    <div class="table-bar">
      <el-input placeholder="Search target..." v-model="searchInput" class="search-input" style="width: 500px">
        <template #prepend>
          <el-select v-model="selected" placeholder="Select" style="width: 150px">
            <el-option v-for="item in selectlist" :key="item.Prop" :label="item.Name" :value="item.Prop" />
          </el-select>
        </template>
      </el-input>
      <el-pagination id="el-pagination" v-model:currentPage="currentPage" :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="pageSize" :pager-count="9" :page-count="pageCount" background :total="filterData.length"
        layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <el-table :data="
      filterData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    " stripe style="width: 100%;height: 100%;" @sort-change="handleSortChange">
      <el-table-column sortable prop="Name" label="Name" width="120" />
      <el-table-column sortable prop="ChEMBL ID" label="ChEMBL ID" width="200" />
      <el-table-column sortable prop="Source Tissue" width="120" label="Source Tissue" />
      <el-table-column sortable prop="Category" width="120" label="Category" />
      <el-table-column sortable prop="Activities" width="120" label="Activities" />
      <el-table-column sortable prop="Actives" width="120" label="Actives" />
      <el-table-column sortable prop="Inactives" width="120" label="Inactives" />
      <el-table-column sortable prop="test AUC" width="120" label="AUC" />
      <el-table-column sortable prop="test ACC" width="120" label="ACC" />
      <el-table-column sortable prop="test SE" width="120" label="SE" />
      <el-table-column sortable prop="test SP" width="120" label="SP" />
<!--      <el-table-column sortable prop="test F1" width="120" label="F1" />-->
      <!-- <el-table-column sortable prop="test BA" width="120" label="BA" /> -->
<!--      <el-table-column sortable prop="test MCC" width="120" label="MCC" />-->
      <!-- <el-table-column sortable prop="test TN" width="120" label="TN" />
      <el-table-column sortable prop="test TP" width="120" label="TP" />
      <el-table-column sortable prop="test FN" width="120" label="FN" />
      <el-table-column sortable prop="test FP" width="120" label="FP" /> -->
      <el-table-column label="Link" width="120" class="detail-column">
        <template #default="scope">
          <button class="detail-btn" size="small" @click="turnToLink(scope.row['links'])">
            Detail
          </button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import TableData from "../api/data/Cancer_Cell_info_database.json";
import { valueToFixed } from "../../utils";
export default {
  props: ["modename"],
  data() {
    return {
      currentPage: 1,
      modelname: "",
      pageSize: 10,
      ifshow: false,
      data: [],
      detailData: [],
      searchInput: "",
      selected: "Name",
      selectlist: [
        { Name: "Name", Prop: "Name" },
        { Name: "ChEMBL ID", Prop: "ChEMBL ID" },
        { Name: "Source Tissue", Prop: "Source Tissue" },
        { Name: "Category", Prop: "Category" },
        { Name: "Activities", Prop: "Activities" },
        { Name: "Actives", Prop: "Actives" },
        { Name: "Inactives", Prop: "Inactives" },
        { Name: "AUC", Prop: "test AUC" },
        { Name: "ACC", Prop: "test ACC" },
        { Name: "SE", Prop: "test SE" },
        { Name: "SP", Prop: "test SP" },
        { Name: "F1", Prop: "test F1" },
        { Name: "BA", Prop: "test BA" },
        { Name: "MCC", Prop: "test MCC" },
        { Name: "TN", Prop: "test TN" },
        { Name: "TP", Prop: "test TP" },
        { Name: "FN", Prop: "test FN" },
        { Name: "FP", Prop: "test FP" },
      ],
    };
  },

  computed: {
    resultData() {
      return this.data;
    },
    //根据页面大小计算总页数
    pageCount() {
      return Math.ceil(this.filterData.length / this.pageSize);
    },
    filterData() {
      let input = this.searchInput && this.searchInput.toLowerCase();
      console.log(input);
      if (input == "") return this.resultData;
      else {
        return this.resultData.filter((item) => {
          return (item[this.selected] + "").toLowerCase().match(input);
        });
      }
    },
  },
  components: {},
  mounted() {
    this.data = TableData.map(item => {
      return {
        ...item,
        "test AUC": item["test AUC"].toFixed(4),
        "test ACC": item["test ACC"].toFixed(4),
        "test SE": item["test SE"].toFixed(4),
        "test SP": item["test SP"].toFixed(4),
        "test F1": valueToFixed(item["test F1"],4),
        "test MCC": valueToFixed(item["test MCC"],4),
      }
    });
  },
  methods: {
    turnToLink(Link) {
      window.open(Link, "_blank");
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // 处理页面下标改变
      this.currentPage = val;
    },

    /**默认的排序只针对当前页，所以要重写处理分页后的表格排序
     * 1. String： 按字典序
     * 2. Number： 按数指大小
     */
    handleSortChange(target) {
      if (target.prop == "name") {
        // 实际数据中只有targetName为String，所以单独处理
        if (target.order == "ascending") {
          this.resultData = this.resultData.sort((a, b) => {
            return a[target.prop] < b[target.prop] ? -1 : 1;
          });
        } else if (target.order == "descending") {
          this.resultData.sort((a, b) => {
            return a[target.prop] > b[target.prop] ? -1 : 1;
          });
        }
      } else {
        if (target.order == "ascending") {
          this.resultData = this.resultData.sort((a, b) => {
            return a[target.prop] - b[target.prop];
          });
        } else if (target.order == "descending") {
          this.resultData.sort((a, b) => {
            return b[target.prop] - a[target.prop];
          });
        }
      }
    },
  },
};
</script>

<style>
.table-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.search-input {
  font-size: 15px;
}

#el-pagination .el-pagination__total,
#el-pagination .el-pagination__jump {
  font-size: 15px;
  color: white;
  /* font-weight: li */
}


#el-pagination .el-pager .number.active {
  background: var(--color2-dark);
}

#el-pagination .el-pager .number:hover,
#el-pagination .btn-prev.hover,
#el-pagination .btn-next.hover {
  background: var(--color2-light);
  color: var(--color1-dark);
}

.el-table__header-wrapper .el-table__cell {
  font-size: 12pt;
  color: var(--color1-dark);
}

.el-table__body .el-table__row,
.el-table__body .el-table__row:hover {
  background: var(--color2-light);
  font-size: 12pt;
}

.el-table .cell {
  text-align: center;
}

.detail-btn {
  font-size: 12pt;
  border-radius: 5px;
  background: none;
  outline: none;
  padding: 5px;
  border: 1px solid var(--color2-dark);
  cursor: pointer;
}

.detail-btn:hover {
  background: var(--color2-light);
}

.el-table {
  width: 100px;
}
</style>