<template>
  <div class="Resource">
    <h1 class="title">Resource</h1>
    <div class="download-btns">
      <div class="download-btn" @click="download('datasets.zip')">
        <img class="download-icon" src="../assets/download_icon.png" alt="download datasets">
        <div class="download-title">
          Datasets
        </div>
      </div>
      <div class="download-btn" @click="download('deepcancer_local.zip')">
        <img class="download-icon" src="../assets/download_icon.png" alt="download software">
        <div class="download-title">
          Software
        </div>
      </div>
    </div>
    <el-menu class="Menu" :default-active="Index" mode="horizontal">
      <el-menu-item @click="showCancer" index="1">Cancer Cell</el-menu-item>
      <el-menu-item @click="showNci" index="2">NCI60</el-menu-item>
      <el-menu-item @click="showAnti" index="3">Anti-cancer Target</el-menu-item>
    </el-menu>
    <CancerTable v-show="Cancer"></CancerTable>
    <NCITable v-show="Nci"></NCITable>
    <AntiTable v-show="Anti"></AntiTable>
  </div>
</template>

<script>
import cancerTable from "../components/cancerTable.vue";
import nciTable from "../components/nciTable.vue";
import antiTable from "../components/antiTable.vue";

export default {
  name: "contact",
  data() {
    return {
      Index: "1",
      Cancer: true,
      Nci: false,
      Anti: false,
    };
  },
  methods: {
    showCancer() {
      this.Cancer = true;
      this.Nci = false;
      this.Anti = false;
    },
    showNci() {
      this.Cancer = false;
      this.Nci = true;
      this.Anti = false;
    },
    showAnti() {
      this.Cancer = false;
      this.Nci = false;
      this.Anti = true;
    },
    download(url) {
      window.open(
        this.$store.state.downloadUrl + "/media/" + url
      );
    }
  },
  components: {
    CancerTable: cancerTable,
    NCITable: nciTable,
    AntiTable: antiTable,
  },
};
</script>

<style scoped>
.title-font {
  font-size: 28pt;
  font-weight: 700;
}

.text-font {
  font-size: 14pt;
  font-weight: 500;
}

.Resource {
  display: flex;
  /* background: linear-gradient(var(--color1-light), var(--color1-dark)); */
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  min-height: 66vh;
}

.Menu {
  width: 800px;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0);
  color: white;
}

.Menu .el-menu-item {
  color: white;
}

.column-flex {
  display: flex;
  flex-direction: column;
}

.row-flex {
  display: flex;
  flex-direction: row;
}

.title {
  font-size: 28pt;
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
  color: white;
}

.download-btns {
  position: absolute;
  right: 150px;
  top: 100px;
  display: flex;
}

.download-btn {
  display: flex;
  margin: 0px 10px;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
  background-color: #fcfcfc;
}


.download-btn .download-title {
  font-size: 16px;
  padding: 0 8px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.download-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}


.download-icon {
  width: 24px;
  height: 24px;
  background-color: #03468f;
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>
